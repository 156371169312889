import React from 'react';
import Row from './StartPageRow';
import Box from './StartPageBox';
import Title from './StartPageTitle';
import styled from 'react-emotion';

const Container = styled('div')`
  ${({ theme }) => theme.below.sm} {
    .row-component {
      padding: 0;
      .box-component.wide {
        height: 65.5vw;
      }
    }
  }
`;

const StartPageMainCampaign = ({
  backgroundImageUrl1,
  backgroundImageUrl2,
  buttonText1,
  buttonText2,
  buttonUrl1,
  buttonUrl2,
  title,
  subtitle,
  ...props
}) => (
  <Container className="main-campaign-component" style={props}>
    {(title || subtitle) && <Title title={title} subtitle={subtitle} />}
    <Row>
      <Box
        backgroundImageUrl={backgroundImageUrl1}
        buttonText={buttonText1}
        buttonUrl={buttonUrl1}
        wide
        alignX={'left'}
        sizes={[1, 1, 2 / 3, 2 / 3, '51rem']}
      />
      <Box
        backgroundImageUrl={backgroundImageUrl2}
        buttonText={buttonText2}
        buttonUrl={buttonUrl2}
        sizes={[1, 1, 1 / 3, 1 / 3, '25rem']}
      />
    </Row>
  </Container>
);

export default StartPageMainCampaign;
