import Analytics from '@jetshop/core/analytics/Analytics';
import { trackPageEvent } from '@jetshop/core/analytics/tracking';
import React from 'react';
import styled from 'react-emotion';
import MaxWidth from '../Layout/MaxWidth';
import LoadingPage from '../LoadingPage';
import StartPageCampaign from './Content/StartPageCampaign';
import StartPageCategories from './Content/StartPageCategories';
import StartPageHero from './Content/StartPageHero';
import StartPageMainCampaign from './Content/StartPageMainCampaign';
import StartPageProductGrid from './Content/StartPageProductGrid';
import StartPageInformation from './Content/StartPageInformation';
import StartPageInformationItem from './Content/StartPageInformationItem';
import startPageQuery from './StartPageQuery.gql';
import DynamicContent from '../DynamicContent';
import { EztradStyling } from './EztradStyling';
import useShopId from '../../utils/useShopId';
import { useQuery } from 'react-apollo';

const startPageComponents = {
  hero: StartPageHero,
  mainCampaign: StartPageMainCampaign,
  categories: StartPageCategories,
  campaign: StartPageCampaign,
  information: StartPageInformation,
  informationItem: StartPageInformationItem,
  products: StartPageProductGrid
};

const StartPageWrapper = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.green};
  z-index: 10;
  position: relative;

  > div:nth-child(1n + 2) {
    margin-top: 4.5rem;
  }

  ${({ theme }) => theme.below.sm} {
    > div:nth-child(1n + 2) {
      margin-top: 2.75rem;
    }
  }
`;

export const MainSectionWrapper = styled(MaxWidth)`
  width: 100%;
  align-self: center;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  ${({ theme }) => theme.below.sm} {
    padding-left: 0px;
    padding-right: 0px;
  }
`;
const StartPage = ({ location: { pathname, key } }) => {
  const store = useShopId();
  const isEztrad = store === 'eztrad';

  const { loading, data } = useQuery(startPageQuery);

  if (loading)
    return (
      <StartPageWrapper className={isEztrad ? EztradStyling : ''}>
        <LoadingPage />
      </StartPageWrapper>
    );

  if (!data) return null;

  return (
    <StartPageWrapper className={isEztrad ? EztradStyling : ''}>
      <DynamicContent
        content={data.startPage.content}
        additionalComponents={startPageComponents}
      />
    </StartPageWrapper>
  );
};

export default StartPage;
