import React from 'react';
import Row from './StartPageRow';
import Box from './StartPageBox';
import Title from './StartPageTitle';
import styled from 'react-emotion';

const Container = styled('div')`
  ${({ theme }) => theme.below.sm} {
    .row-component > div {
      margin: 0;
      grid-gap: 0.75rem;
      grid-template-columns: repeat(2, 1fr);
      .box-component {
        margin: 0;
        max-height: 60vw;
        :last-child {
          grid-column: 1 / -1;
          max-height: 120vw;
        }
      }
    }
  }
`;

const StartPageCategories = ({
  backgroundImageUrl1,
  backgroundImageUrl2,
  backgroundImageUrl3,
  buttonText1,
  buttonText2,
  buttonText3,
  buttonUrl1,
  buttonUrl2,
  buttonUrl3,
  title,
  subtitle,
  ...props
}) => (
  <Container className="categories-component" style={props}>
    <Title title={title} subtitle={subtitle} />
    <Row>
      <Box
        backgroundImageUrl={backgroundImageUrl1}
        buttonText={buttonText1}
        buttonUrl={buttonUrl1}
        sizes={[1 / 2, 1 / 2, 1 / 3, 1 / 3, '25rem']}
      />
      <Box
        backgroundImageUrl={backgroundImageUrl2}
        buttonText={buttonText2}
        buttonUrl={buttonUrl2}
        sizes={[1 / 2, 1 / 2, 1 / 3, 1 / 3, '25rem']}
      />
      <Box
        backgroundImageUrl={backgroundImageUrl3}
        buttonText={buttonText3}
        buttonUrl={buttonUrl3}
        sizes={[1, 1, 1 / 3, 1 / 3, '25rem']}
      />
    </Row>
  </Container>
);

export default StartPageCategories;
