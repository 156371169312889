import React from 'react';
import styled from 'react-emotion';

const Column = styled('div')`
  flex: 1;
  justify-content: center;
  margin: 0 0.5rem;

  :first-child {
    margin-left: 0;
  }
  :last-child {
    margin-right: 0;
  }

  .item-container {
    width: 100%;
    max-width: 200px;
    margin: 0 auto;

    .image-container {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      background-color: ${({ theme }) => theme.colors.green};
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 1.5rem;
      img {
        height: 25px;
      }
    }

    h2 {
      font-weight: 500;
      font-size: 0.9375rem;
      letter-spacing: 0.05em;
      font-variant: small-caps;
      text-transform: lowercase;
      margin-bottom: 0.5rem;
    }

    p {
      font-size: 0.875rem;
      line-height: 180%;
      color: ${({ theme }) => theme.colors.almostBlack};
      max-width: 100%;
    }
  }

  ${({ theme }) => theme.below.sm} {
    flex-direction: column;
    margin: 1.25rem 0;

    :first-child {
      margin-top: 0;
    }
    :last-child {
      margin-bottom: 0;
    }

    .item-container {
      max-width: 275px;
      position: relative;
      padding-left: 4.5rem;

      .image-container {
        position: absolute;
        left: 0;
        top: 0;
      }
    }
  }
`;

const StartPageInformationItem = ({ icon, title, text }) => (
  <Column className="information-item-component">
    <div className="item-container">
      {icon && (
        <div className="image-container">
          <img src={icon} />
        </div>
      )}
      {title && <h2>{title}</h2>}
      {text && <p>{text}</p>}
    </div>
  </Column>
);

export default StartPageInformationItem;
