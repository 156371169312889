export default function propNameToFlexName(propName) {
  switch (propName) {
    case 'top':
    case 'left':
      return 'flex-start';
    case 'bottom':
    case 'right':
      return 'flex-end';
    case 'middle':
      return 'center';
    default:
      return propName;
  }
}
