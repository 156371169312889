import React from 'react';
import styled from 'react-emotion';
import MaxWidth from '../../Layout/MaxWidth';
import { ProductGrid } from '../../CategoryPage/ProductGrid';
import productsQuery from '../../ProductPage/Products.gql';
import Title from './StartPageTitle';
import { useQuery } from 'react-apollo';

// The horizontal scroll is styled in a way that on any resolution smaller than 'lg', a part of the second/third item can be seen, indicating it is a horizontally scrollable container.
const StyledProductGrid = styled(ProductGrid)`
  flex-wrap: nowrap;

  .product-card > a {
    margin-bottom: 0;
  }

  ${({ theme }) => theme.below.md} {
    overflow-x: auto;
    .product-card {
      width: 300px;
      flex-shrink: 0;
      max-width: 40vw;
    }
  }
  ${({ theme }) => theme.below.sm} {
    .product-card {
      max-width: 60vw;
      width: 340px;
    }
  }
  ${({ theme }) => theme.below.xs} {
    .product-card {
      max-width: 75vw;
    }
  }

  @supports (display: grid) {
    grid-template-columns: repeat(4, 1fr);
    ${({ theme }) => theme.below.md} {
      grid-template-columns: repeat(10, 1fr);
    }
  }
`;

const StartPageProductGrid = ({ products, title, subtitle, ...props }) => {
  const productsArray = products.split(',');
  const articles = productsArray.slice(0, 4);
  for (let x = 0; x < articles.length; x++) articles[x] = articles[x].trim();

  const { data } = useQuery(productsQuery, {
    variables: { articles }
  });

  if (!data || !data.products) return null;

  return (
    <MaxWidth className="product-grid-component" style={props}>
      {(title || subtitle) && <Title title={title} subtitle={subtitle} />}
      <StyledProductGrid
        products={data.products}
        imageSizes={[1 / 2, 1 / 2, 1 / 4]}
        list={'Start page - Selected products'}
      />
    </MaxWidth>
  );
};

export default StartPageProductGrid;
