import React from 'react';
import MaxWidth from '../../Layout/MaxWidth';
import styled from 'react-emotion';

const Container = styled('div')`
  padding: 6rem 0;
  :last-child {
    margin-bottom: -3rem;
  }
  ${({ theme }) => theme.above.sm} {
    > div {
      flex-direction: row;
    }
  }
`;

const StartPageInformation = ({ children, ...props }) => (
  <Container className="information-component" style={props}>
    <MaxWidth>{children}</MaxWidth>
  </Container>
);

export default StartPageInformation;
