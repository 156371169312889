import React from 'react';
import Row from './StartPageRow';
import Box from './StartPageBox';
import styled from 'react-emotion';

const Container = styled('div')`
  ${({ theme }) => theme.below.xl} {
    .box-component > div {
      padding: 2rem;
      h2 {
        font-size: 2.375rem;
      }
    }
  }
  ${({ theme }) => theme.below.lg} {
    .box-component > div {
      padding: 1rem;
      h2 {
        font-size: 2rem;
      }
    }
  }
  ${({ theme }) => theme.below.md} {
    .box-component.wide {
      flex-basis: 0;
    }
  }
  ${({ theme }) => theme.below.sm} {
    .row-component {
      padding: 0;

      .box-component:first-child {
        height: 102.1538vw;
      }
      .box-component:last-child {
        height: 65.5vw;
      }
    }
  }
`;

const StartPageCampaign = ({
  backgroundImageUrl,
  buttonText,
  buttonUrl,
  title,
  text,
  ...props
}) => (
  <Container className="campaign-component" style={props}>
    <Row>
      <Box
        buttonText={buttonText}
        buttonUrl={buttonUrl}
        alignY={'center'}
        title={title}
        text={text}
      />
      <Box
        backgroundImageUrl={backgroundImageUrl}
        wide={true}
        sizes={[1, 1, 1 / 2, 2 / 3, '51rem']}
      />
    </Row>
  </Container>
);

export default StartPageCampaign;
